/**
 * @generated SignedSource<<89788003f7cbdd94ad1bb461e1c5f03e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBar_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_project">;
  readonly " $fragmentType": "TopBar_project";
};
export type TopBar_project$key = {
  readonly " $data"?: TopBar_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBar_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBar_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioBreadcrumbs_project"
    }
  ],
  "type": "ProjectInterface",
  "abstractKey": "__isProjectInterface"
};

(node as any).hash = "d5316da864d0d9b7dc14faf159cb678b";

export default node;
