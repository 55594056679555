/**
 * @generated SignedSource<<a42fc2624868dffaa87284685d7abdf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectStatus = "COMPLETED" | "OK" | "PAUSED" | "%future added value";
export type ProjectSurveyStatus = "ACTIVATED" | "BEHIND_PRELIMINARY_SCHEDULE" | "BEHIND_SCHEDULE" | "COMPLETED" | "ONGOING" | "PRELIMINARY" | "SCHEDULED" | "%future added value";
export type ProjectsPageQuery$variables = {
  onlyMyProjects?: boolean | null | undefined;
  portfolioPath: string;
  teamPath: string;
};
export type ProjectsPageQuery$data = {
  readonly pqiUser: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectsEmptyState_pqiUser">;
  } | null | undefined;
  readonly team: {
    readonly portfolio: {
      readonly projectSurveys: ReadonlyArray<{
        readonly closedAt: string | null | undefined;
        readonly endDate: string | null | undefined;
        readonly projectId: string;
        readonly status: ProjectSurveyStatus;
        readonly surveyDate: string;
        readonly surveyNumber: number;
      }>;
      readonly projects: ReadonlyArray<{
        readonly __typename: string;
        readonly createdAt: string;
        readonly id: string;
        readonly name: string;
        readonly path: string;
        readonly projectTeamShares?: ReadonlyArray<{
          readonly id: string;
          readonly team: {
            readonly name: string;
          };
        }>;
        readonly responsiblePerson: {
          readonly emailAddress: string;
          readonly fullName: string;
          readonly id: string;
        } | null | undefined;
        readonly sharedProjectDetails?: {
          readonly teamName: string;
        };
        readonly status: ProjectStatus;
        readonly surveyCount: number;
      }>;
    } | null | undefined;
    readonly projectCategories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectsEmptyState_team">;
  } | null | undefined;
};
export type ProjectsPageQuery = {
  response: ProjectsPageQuery$data;
  variables: ProjectsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyMyProjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portfolioPath"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamPath"
},
v3 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "teamPath"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "portfolioPath"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surveyNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surveyDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closedAt",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "onlyMyProjects",
    "variableName": "onlyMyProjects"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "responsiblePerson",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surveyCount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamName",
  "storageKey": null
},
v20 = [
  (v15/*: any*/),
  (v13/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectCategory",
  "kind": "LinkedField",
  "name": "projectCategories",
  "plural": true,
  "selections": (v20/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectsEmptyState_pqiUser"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "team",
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "teamByPath",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "portfolioPath",
                "variableName": "portfolioPath"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ProjectsEmptyState_team"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectSurvey",
                "kind": "LinkedField",
                "name": "projectSurveys",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "projects",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v7/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectTeamShare",
                        "kind": "LinkedField",
                        "name": "projectTeamShares",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TeamBasicInfo",
                            "kind": "LinkedField",
                            "name": "team",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Project",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SharedProjectDetails",
                        "kind": "LinkedField",
                        "name": "sharedProjectDetails",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ReceivedProject",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PQiUserGrant",
            "kind": "LinkedField",
            "name": "grants",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portfolioId",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "team",
        "args": (v3/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "teamByPath",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectSurvey",
                "kind": "LinkedField",
                "name": "projectSurveys",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "projects",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v7/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectTeamShare",
                        "kind": "LinkedField",
                        "name": "projectTeamShares",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TeamBasicInfo",
                            "kind": "LinkedField",
                            "name": "team",
                            "plural": false,
                            "selections": (v20/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Project",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SharedProjectDetails",
                        "kind": "LinkedField",
                        "name": "sharedProjectDetails",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ReceivedProject",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f96c08a9389a81cff13a905e6a62f531",
    "id": null,
    "metadata": {},
    "name": "ProjectsPageQuery",
    "operationKind": "query",
    "text": "query ProjectsPageQuery(\n  $teamPath: String!\n  $portfolioPath: String!\n  $onlyMyProjects: Boolean\n) {\n  pqiUser {\n    ...ProjectsEmptyState_pqiUser\n    id\n  }\n  team: teamByPath(path: $teamPath) {\n    ...ProjectsEmptyState_team_2X20fV\n    portfolio(path: $portfolioPath) {\n      __typename\n      projectSurveys {\n        projectId\n        surveyNumber\n        status\n        surveyDate\n        endDate\n        closedAt\n        id\n      }\n      projects(onlyMyProjects: $onlyMyProjects) {\n        __typename\n        id\n        path\n        name\n        responsiblePerson {\n          id\n          fullName\n          emailAddress\n        }\n        status\n        surveyCount\n        createdAt\n        ... on Project {\n          projectTeamShares {\n            id\n            team {\n              name\n              id\n            }\n          }\n        }\n        ... on ReceivedProject {\n          sharedProjectDetails {\n            teamName\n            id\n          }\n        }\n      }\n      id\n    }\n    projectCategories {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectsEmptyState_pqiUser on PQiUser {\n  grants {\n    role\n    teamId\n    portfolioId\n    projectId\n  }\n}\n\nfragment ProjectsEmptyState_team_2X20fV on Team {\n  id\n  portfolio(path: $portfolioPath) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d332c79d7abcdc91ee29c094f0f19807";

export default node;
