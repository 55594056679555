import { graphql, loadQuery } from 'react-relay';
import type { Environment } from 'react-relay';
import type { ProjectSurveysPageQuery$variables } from './__generated__/ProjectSurveysPageQuery.graphql.ts';
export type * from './__generated__/ProjectSurveysPageQuery.graphql.ts';

export const loadProjectSurveysPageQuery = (
    environment: Environment,
    variables: ProjectSurveysPageQuery$variables,
) => {
    return loadQuery(environment, projectSurveysPageQuery, variables);
};

export const projectSurveysPageQuery = graphql`
    query ProjectSurveysPageQuery(
        $projectPath: String!
        $teamPath: String!
        $portfolioPath: String!
    ) {
        team: teamByPath(path: $teamPath) {
            ...ProjectSurveyCard_team
            projectCategories {
                id
            }
            portfolio(path: $portfolioPath) {
                project(path: $projectPath) {
                    ...useIsProjectAdmin_project
                    ...ProjectSurveysEmptyState_project
                    ...ProjectSurveyCard_project        
                    __typename       
                    surveys {
                        ...ProjectSurveyCard_survey
                    }
                    responsiblePerson {
                        id
                    }
                    projectCategory {
                        id
                    }
                    budget {
                        amount
                    }
                }
            }
        }
        pqiUser {
            ...useIsProjectAdmin_pqiUser
            ...ProjectSurveysEmptyState_pqiUser
            authUser {
                ...ProjectSurveyCard_user
            }
        }
    }
`;
